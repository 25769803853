import './App.css';
import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Authenticate from './components/AuthUI'
import Home from './components/BottomNavigate/home';
import Profile from './components/BottomNavigate/profile';
import Settings from './components/BottomNavigate/settings';
import BottomFooter from './components/bottomFooter';
import PAMDashboard from "../src/components/PAMDashboard/PAMDashboard";
import Header from './components/Header';


const RouteElement = ({ location, from, children }) => {
  const { pathname } = location
  const LoggedinFlag = window.localStorage.getItem('spaceio-key')
  return pathname === '/login' ? (LoggedinFlag ?
    <Navigate to={from} /> : (children)) : (LoggedinFlag ? children :
      <Navigate to={'/login'} state={{ from: location }} />)
}

const ProtectedRoute = ({ children, path }) => {
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/' } }
  return <RouteElement location={location} from={from} children={children} caseSensitive />
}

function Main() {
  return (
    <div className="container-fluid g-0">
      <div className="row justify-content-center g-0 mainDiv">
        <div className='flex flex-col h-screen overflow-hidden'>
          <Header />
          <div className="container-fluid g-0  flex-1 bg-[#f5f5f5] col-lg-8 overflow-y-scroll no-scrollbar">
            <div className="row justify-content-center g-0 mainDiv">
              <div className=" flex flex-col ">
                <Routes>
                  <Route index element={<Authenticate />}></Route>
                  <Route path='login' element={<ProtectedRoute><Authenticate /></ProtectedRoute>}></Route>
                  <Route path='home' element={<ProtectedRoute><Home /></ProtectedRoute>}></Route>
                  <Route path='Profile' element={<ProtectedRoute><Profile /></ProtectedRoute>}></Route>
                  <Route path='Setting' element={<ProtectedRoute><Settings /></ProtectedRoute>}></Route>
                  <Route path='PAMDashboard' element={<ProtectedRoute><PAMDashboard /></ProtectedRoute>}></Route>
                </Routes>
              </div>
            </div>
          </div>
          <BottomFooter />
        </div>
      </div>
    </div >
  );
}

const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  )
}
export default App;
