import React, { useState } from "react";
import EditIcon from "../../image/editIcon.svg";

function TailWindDropDown({ listOptions, ShowEditIcon, onEditIcon, isOpen, handleSelectOptions }) {
    const [searchQuery, setSearchQuery] = useState("");

    const handleSelectOptionsMain = (option) => {
        handleSelectOptions(option)
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredOptions = listOptions.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <div className="relative inline-block">
                {ShowEditIcon && (
                    <button
                        type="button"
                        id="menu-button"
                        aria-expanded={ShowEditIcon}
                        aria-haspopup="true"
                        onClick={() => {
                            onEditIcon(isOpen);
                            setSearchQuery("")
                        }}
                        className="focus:outline-none"
                    >
                        <img
                            className="h-5 w-5 text-white cursor-pointer"
                            src={EditIcon}
                            alt="EditIcon"
                        />
                    </button>
                )}
                {isOpen && (
                    <div
                        className="absolute z-10 right-0 mt-2 w-60 bg-white rounded-md shadow-lg overflow-y-auto max-h-48"
                        style={{ top: "calc(100% + 0.5rem)" }}
                    >
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            placeholder="Search"
                            className="block w-full px-4 py-2 text-sm text-gray-700 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                        />
                        <div className="max-h-70">
                            {filteredOptions.map((option, index) => (
                                <button
                                    key={index}
                                    className="block px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    onClick={() => handleSelectOptionsMain(option)}
                                >
                                    {option.label}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default TailWindDropDown;
