import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import am4themes_material from "@amcharts/amcharts4/themes/dataviz";


class TrendsPage extends React.Component {
    constructor(props) {
        super(props);
        this.setContainerRef = element => {
            this.containerRef = element;
        };
    }
    prepareChart = () => {

        // Themes end

        // Create chart instance
        am4core.options.commercialLicense = true;
        // am4core.options.autoDispose = true;

        var chart = am4core.create(this.containerRef, am4charts.XYChart);
        chart.paddingRight = 20;
        chart.background.fill = '#ffffff'

        // Add data
        this.newDataForChart = () => {
            chart.data = this.props.dataForChart

        }
        chart.data = this.props.dataForChart


        // Create axes

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "year";
        // categoryAxis.renderer.minGridDistance = 50;
        // categoryAxis.renderer.grid.template.location = 0.5;
        // categoryAxis.startLocation = 0.5;
        // categoryAxis.endLocation = 0.5;
        categoryAxis.renderer.labels.template.fontSize = 12;


        // Create value axis
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.strokeWidth = 0;
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.renderer.labels.template.fontWeight = 700;


        const { insideStrokeColor } = this.props;

        // Create series
        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "year";
        series.strokeWidth = 2;
        series.stroke = am4core.color(insideStrokeColor);
        series.fill = series.stroke;
        series.background.fill = am4core.color("#ffffff");
        series.tooltipText = "{valueY}";
        series.connect = false;
        series.minBulletDistance = 10;
        // series.stroke= am4core.color("grey");
        // series.tensionX = 0.77;

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.tooltipText = "{valueY}";
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 2;
        bullet.circle.fill = am4core.color("#fff");

        var bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1.5;

        // bullet is added because we add tooltip to a bullet for it to change color
        // var bullet = series.bullets.push(new am4charts.Bullet());
        // bullet.tooltipText = "{valueY}";

        // bullet.adapter.add("fill", function(fill, target){
        //     if(target.dataItem.valueY < lowerBound || target.dataItem.valueY > upperBound){
        //         return am4core.color(outsideStrokeColor);
        //     }
        //       return am4core.color(insideStrokeColor);
        // });


        // var range = valueAxis.createSeriesRange(series);
        // range.value = lowerBound;
        // range.endValue = upperBound;
        // range.contents.stroke = am4core.color(insideStrokeColor);
        // range.contents.fill = range.contents.stroke;

        chart.cursor = new am4charts.XYCursor();

        this.dispose = () => {
            chart.dispose();
            // am4core.disposeAllCharts();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.dataForChart !== prevProps.dataForChart && !this.props.temperature) {
            this.newDataForChart();
        }
        if (this.props.dataForChart !== prevProps.dataForChart && this.props.temperature) {
            this.dispose()
            this.prepareChart()
        }
    }

    componentDidMount() {
        this.prepareChart();
    }

    componentWillUnmount() {

    }
    render() {
        return (
            <div ref={this.setContainerRef} style={{ width: "100%", height: "200px", marginTop: "20px", borderRadius: "10px" }}></div>
        )
    }
}



export default TrendsPage;