import React from "react";
import "./RectangleCard.css";
import BLEicon from "../../image/bluetooth.svg"
import Rename from "../../image/rename.svg"
import Wifi from "../../image/wifi.svg"


function RectangleCardSetting({ spaceName, myDevice, isparied, deviceBleStatus, deviceId, onBLEClick, onWiFiClick, onRename }) {
    // let deviceOnlineOffline = ""
    // let textClassName = ""
    // if (deviceBleStatus) {
    //      deviceOnlineOffline = deviceBleStatus ? "Connected" : "Disconnected"
    //      textClassName = deviceBleStatus ? "green-text" : "red-text";
    // }
    const deviceOnlineOffline = deviceBleStatus ? "Paired" : "Unpaired"
    const textClassName = deviceBleStatus ? "green-text" : "red-text";

    return (
        <div className="rectangle-card-timP-body p-2 my-3">
            <div className=" flex justify-between items-center">
                <div className=" flex flex-col ml-4">
                    <span className="rectangle-card-timP-body-top-content1">{spaceName}</span>
                    <div className="rectangle-card-timP-body-bottom">
                        <div className=" flex flex-row ">
                            <span className={textClassName}>{deviceOnlineOffline}</span>
                            <span className="ml-2">{deviceId}</span>
                        </div>
                    </div>
                </div>
                <div className=" flex flex-row gap-2 justify-center pl-2 pr-2">
                    {myDevice ?
                        // {Rename }
                        <>

                            {/* { wifi or BLE } */}
                            {isparied ?
                                <img
                                    className='h-8 w-8 text-white cursor-pointer'
                                    src={Wifi}
                                    alt="Wifi"
                                    onClick={onWiFiClick}
                                />
                                :
                                <>
                                    <img
                                        className='h-8 w-8 text-white cursor-pointer'
                                        src={Rename}
                                        alt="Rename"
                                        onClick={onRename}
                                    />
                                    <img
                                        className='h-8 w-8 text-white cursor-pointer'
                                        src={BLEicon}
                                        alt="BLE"
                                        onClick={onBLEClick}
                                    />
                                </>
                            }
                        </>
                        : <>
                            {isparied ?
                                <img
                                    className='h-8 w-8 text-white cursor-pointer'
                                    src={Wifi}
                                    alt="Wifi"
                                    onClick={onWiFiClick}
                                />
                                :
                                <></>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default RectangleCardSetting;