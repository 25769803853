import React from "react";

function Button1({ text, borderRadius, background, color, onClick, style, tabIndex, disabled }) {

    const buttonStyle = {
        fontWeight: "700",
        fontSize: "14px",
        background: background,
        borderRadius: borderRadius,
        color: color,
        border: "transparent",
        width: "100%",
        minHeight: "38px",
        margin: "1rem 0",
        ...style
    }
    return (

        // <div className="col-md-8">
        <button disabled={disabled}
            style={buttonStyle} onClick={onClick ? onClick : (e) => e.preventDefault()} tabIndex={tabIndex ? tabIndex : null}>
            {text}
        </button>
        // </div>
    );
}

export default Button1;