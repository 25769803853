import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
// import $ from 'jquery';
// import userRole from "./images/user_role.png";
import carbonIcon from "../../image/carbon.svg";
import humidityIcon from "../../image/humidity.svg";
import tempIcon from "../../image/temp.svg";
import vocIcon from "../../image/voc.svg"
import Toggle from "./toggle.js"
// import Loader from './components/ui/loader';

class NowCards extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        // console.log(this.props)


    }

    render() {
        window.onbeforeunload = function () {
            return "Leaving this page will reset the wizard";
        };

        return (
            <div className='pb-5'>
                <div className="r1">
                    {/* <div className="co2 border rounded-lg">
                        <div><img src={userRole} alt="user"></img></div>
                        <p style={{ color: this.props.color, fontWeight: "500" }}>{this.props.occupancyState}</p>
                        <p className="label-for-card">Indicator</p>
                        <div> </div>
                    </div> */}

                    <div className="co2  border rounded-lg" >
                        <div><img src={carbonIcon} alt="carbonIcon"></img></div>
                        <div>{this.props.co2}</div>
                        <p className="label-for-card">CO<sub>2</sub><br />(ppm) </p>
                    </div>
                    {
                        this.props.voc !== "NaN" ?
                            <div className="co2 border rounded-lg">
                                <div><img src={vocIcon} alt="user"></img></div>

                                <div>{/*this.props.vocReadingt*/this.props.voc}</div>
                                <p className="label-for-card">VOC<br />(Index) </p>
                                <div> </div>
                            </div>
                            :

                            <div className="co2 border rounded-lg" style={{ marginTop: 40, }}>
                                <div><img src={tempIcon} alt="temp"></img></div>
                                {this.props.temp}

                                <span className="label-for-card">Temperature <br />{this.props.loadingGraph ? "(°C)" : ""}</span>
                                {!this.props.loadingGraph ? <Toggle
                                    selected={this.props.selected}
                                    toggleSelected={this.props.toggleSelected}
                                /> : ""}
                            </div>
                    }
                </div>

                <div className="r2" >
                    {this.props.voc !== "NaN" ?
                        <div className="co2 border rounded-lg" style={{ marginTop: 40, }}>
                            <div><img src={tempIcon} alt="temp"></img></div>
                            {this.props.temp}

                            <span className="label-for-card">Temperature <br />{this.props.loadingGraph ? "(°C)" : ""}</span>
                            {!this.props.loadingGraph ? <Toggle
                                selected={this.props.selected}
                                toggleSelected={this.props.toggleSelected}
                            /> : ""}


                        </div> : null}
                    <div className="co2 border rounded-lg " style={{ marginTop: 40, }}>
                        <div><img src={humidityIcon} alt="humidityIcon"></img></div>
                        {this.props.humid}

                        <p className="label-for-card"> Humidity<br />(RH%) </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default NowCards;