
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";


function BottomFooter() {

    const location = useLocation();
    const currentPath = location.pathname;
    const [selectedPage, setSelectedPage] = useState(currentPath);


    useEffect(() => {
        setSelectedPage(currentPath); // Update selectedPage when currentPath changes
    }, [currentPath]);

    return (
        <>
            <div className="container-fluid g-0">
                <div className="row justify-content-center g-0 mainDiv">
                    <div className="col-lg-8 max-h-full" >
                        <div className="Navbar bg-white drop-shadow-2xl w-full flex flex-row gap-x-2 justify-evenly p-3">
                            <Link
                                to="/home"
                                className={`flex-row justify-center items-center hover:bg-gray-100 ${selectedPage === "/home" ? "text-[#38D68B]" : "text-[#2E375B]"}`}// Add the condition to change color
                                onClick={() => setSelectedPage("/home")} // Update the selected page when clicked
                            >
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-8 h-8"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                </svg>
                            </Link>
                            <Link
                                to="/Setting"
                                className={`flex-row justify-center items-center ${selectedPage === "/Setting" ? "text-[#38D68B]" : "text-[#2E375B]"}`} // Add the condition to change color
                                onClick={() => setSelectedPage("/Setting")} // Update the selected page when clicked
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-8 h-8"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </Link>
                            <Link
                                to="/Profile"
                                className={`flex-row justify-center items-center ${selectedPage === "/Profile" ? "text-[#38D68B]" : "text-[#2E375B]"}`}// Add the condition to change color
                                onClick={() => setSelectedPage("/Profile")} // Update the selected page when clicked
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-8 h-8"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}


export default BottomFooter;
