import React, { useState } from "react";
import "../mod1/mod1.css";
import Button1 from "../Button1";


import closeIcon from "../../image/closeIcon.svg";
import rename from "../../image/rename.svg";

function Rename(props) {
    const [reName, setRename] = useState(props.nameofDevice ? props.nameofDevice : "");

    const handleReName = (event) => {
        setRename(event.target.value);
    };

    const handleDoneClick = () => {
        props.confirmButton.onClick(reName)
    };

    return (
        <div style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 1 }}>
            <div className="Mod1-parent-container">
                {props.closeModal ?
                    <button
                        className="Mod1-parent-container-content-close-icon"
                        aria-label="close-icon"
                        onClick={props.closeModal}
                    >
                        <img alt="closeIcon" src={closeIcon}></img>
                    </button>
                    : null
                }
                <div className=" text-center font-nunito font-semibold">
                    <span>
                        Customize Your Device Name
                    </span>
                </div>

                {/* <img src={props && props.text && props.text.icon} alt="icon" className=" Mod1-parent-container-content1"></img>
                <span className="Mod1-parent-container-content2 my-3">{props && props.text && props.text['primaryText']}</span>
                <span className="Mod1-parent-container-content3 my-2">{props && props.text && props.text['secondaryText']}</span> */}

                <div className=" w-100 font-nunito">
                    <div>
                        <label className="text-[#444444] font-semibold mt-6">
                            Rename
                        </label>
                        <br />
                        <div className="Mod1-input-field flex items-center gap-0.5 w-100" >
                            <img src={rename} alt="Rename" className='h-5 w-5 text-white' />
                            <input
                                className=" font-semibold outline-none"
                                type="text"
                                placeholder="Rename Your Device"
                                value={reName}
                                onChange={handleReName}
                                style={{ paddingLeft: "10px" }}
                            />
                        </div>
                    </div>

                    <div >
                        <Button1
                            text={props.confirmButton.text}
                            // text={"Customize the name"}
                            borderRadius={"10px"}
                            background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
                            color={"#ffffff"}
                            onClick={handleDoneClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Rename;