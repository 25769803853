import "./loader.css"

function Loader() {
    return (
        <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: 1
        }}>
            <span
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: "50%",
                    fontSize: 16,
                    color: 'white',
                    transform: 'translate(-50%, 10%)'
                }}
            ><div className="loader"></div></span>
        </div>);
}

export default Loader;