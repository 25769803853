
import AuthService from "../Auth/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import alertIcon from "../image/alert.svg"
import StaticModal from "../components/mod1/StaticModal"

const Authenticate = () => {

    var key = localStorage.getItem('spaceio-key')
    const params = new URLSearchParams(window.location.search)
    const navigate = useNavigate();
    const [authCode, setAuthCode] = useState(params.has('xauthcode') ? params.get('xauthcode') : null)
    const [showModal, setShowModal] = useState(false);

    const staticModalInfo = {
        primaryText: "It seems you do not have permission to access this tool",
        secondaryText: "Click below to retry",
        icon: alertIcon,
        inputBox: false,
        confirmButtonText: "OK"
    }

    useEffect(() => {
        async function fetchToken() {
            const result = await AuthService.getAuthToken(authCode);
            localStorage.setItem('spaceio-key', result.headers['X-Auth-Key'] || result.headers['x-auth-key']);
            localStorage.setItem('spaceio-user', JSON.stringify(result.body));
            localStorage.setItem('spaceio-userInfo', JSON.stringify(result.data))

            let user = result.data;
            console.log(user)
            let rolesIds = (user.roles.map(role => { return role.id }))
            const isAutherisedUser = rolesIds.includes(2) && rolesIds.includes(42);

            if (isAutherisedUser)
                navigate('/home')
            else {
                setShowModal(true)
            }
        }
        fetchToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCode])

    if (!params.has('xauthcode')) {
        // window.location.href = 'https://login.dev.afreespace.com/v2/pam?referrer=https://localhost.afreespace.com:8443';
        window.location.href = 'https://login.dev.afreespace.com/v2/pam?referrer=https://pamapp.afreespace.com';
    }


    return (
        <>
            {showModal ?
                <>
                    <StaticModal
                        text={{
                            primaryText: staticModalInfo.primaryText,
                            secondaryText: staticModalInfo.secondaryText,
                            icon: staticModalInfo.icon,
                        }}
                        confirmButton={{
                            text: staticModalInfo.confirmButtonText,
                            onClick: () => {
                                AuthService.logout()
                                navigate("/")
                                setShowModal(false)
                            }
                        }}
                    />
                </>
                :
                <></>
            }
        </>
    )
}

// function Authenticate1() {



//     // const staticModalInfo = {
//     //     primaryText: "Web Bluetooth API is not available for your browser!",
//     //     secondaryText: "To Configure the Devices try to open the App in supported browser",
//     //     icon: infoIcon,
//     //     inputBox: false,
//     //     confirmButtonText: "Home"
//     // }

//     var key = localStorage.getItem('spaceio-key')
//     const params = new URLSearchParams(window.location.search)
//     const navigate = useNavigate();
//     const [authCode, setAuthCode] = useState(params.has('xauthcode') ? params.get('xauthcode') : null)


//     useEffect(() => {
//         async function fetchToken() {
//             const result = await AuthService.getAuthToken(authCode);
//             localStorage.setItem('spaceio-key', result.headers['X-Auth-Key'] || result.headers['x-auth-key']);
//             localStorage.setItem('spaceio-user', JSON.stringify(result.body));
//             let user = result.data;
//             console.log(user);
//             if (user.roles.map(r => r.id).every(e => [42, 2].includes(e)))
//                 navigate('/home')
//             else {
//                 console.log("No having enough permission")
//                 return (
//                     <>
//                         <h1>
//                             No having enough permission
//                         </h1>
//                     </>
//                 )
//             }
//             // navigate('/home')

//             //react router function to send it to selectfloor route
//         }
//         fetchToken();
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [authCode])

//     if (!params.has('xauthcode')) {
//         window.location.href = 'https://login.dev.afreespace.com/v2/pam?referrer=https://localhost.afreespace.com:8443';
//     }
// }



export default Authenticate;