import React, { useState } from "react";
import "../mod1/mod1.css";
import Button1 from "../Button1";


import closeIcon from "../../image/closeIcon.svg";
import device from "../../image/device.svg";
import WiFi from "../../image/wifi.svg";
import Password from "../../image/password.svg";
import Glass from "../../image/glasses.svg"

function WifiFrom(props) {

    const [deviceName, setDeviceName] = useState(props.nameofDevice ? props.nameofDevice : "");
    const [wifi, setWifi] = useState();
    const [password, setPassword] = useState();

    const [showPassord, setShowPassord] = useState(false)

    const handleDeviceNameChange = (event) => {
        setDeviceName(event.target.value);
    };

    const handleWifiChange = (event) => {
        setWifi(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleDoneClick = () => {
        // console.log("Device Name:", deviceName);
        // console.log("Wifi:", wifi);
        // console.log("Password:", password);
        let object = {
            deviceName: deviceName,
            ssid: wifi,
            psk: password
        }

        props.confirmButton.onClick(object)
    };

    // Check if all input fields are filled
    const isFormComplete = deviceName && wifi && password;

    return (
        <div style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 1 }}>
            <div className="Mod1-parent-container">

                {props.closeModal ?
                    <button
                        className="Mod1-parent-container-content-close-icon"
                        aria-label="close-icon"
                        onClick={props.closeModal}
                    >
                        <img alt="closeIcon" src={closeIcon}></img>
                    </button> : null
                }


                <div className=" text-center font-nunito font-semibold">
                    <span>
                        Customize Wi-Fi Setup and Personalize Your Device
                    </span>
                </div>


                <div className=" w-100 font-nunito">
                    <div>
                        <label className=" text-[#444444] font-semibold mt-6">
                            Device Name
                        </label>
                        <br />
                        <div className="Mod1-input-field flex items-center gap-0.5 w-100" >
                            <img src={device} alt="Device" className='h-5 w-5 text-white' />
                            <input
                                className=" font-semibold outline-none"
                                type="text"
                                placeholder="Enter Your Device Name"
                                value={deviceName}
                                onChange={handleDeviceNameChange}
                                style={{ paddingLeft: "10px" }}
                            />
                        </div>
                    </div>

                    <div>
                        <label className=" text-[#444444] font-semibold mt-3">
                            Wi-Fi
                        </label>
                        <br />
                        <div className="Mod1-input-field flex items-center gap-0.5 w-100" >
                            <img src={WiFi} alt="Wifi" className='h-5 w-5 text-white ' />
                            <input
                                className=" font-semibold outline-none"
                                type="text"
                                placeholder="Please Enter Wi-Fi Name"
                                value={wifi}
                                onChange={handleWifiChange}
                                style={{ paddingLeft: "10px" }}
                            />
                        </div>
                    </div>

                    <div>
                        <label className=" text-[#444444] font-semibold mt-3">
                            Password
                        </label>
                        <br />
                        <div className="Mod1-input-field flex items-center gap-0.5 w-100" >
                            <img
                                className='h-5 w-5 text-white cursor-pointer'
                                src={showPassord ? Glass : Password}
                                alt="Wifi"
                                onClick={() => {
                                    setShowPassord(!showPassord)
                                }}
                            />
                            <input
                                className=" font-semibold outline-none"
                                type={showPassord ? "text" : "password"}
                                placeholder="Please Enter Password"
                                value={password}
                                onChange={handlePasswordChange}
                                style={{ paddingLeft: "10px" }}
                            />
                        </div>
                    </div>

                    <div >
                        <Button1
                            text={props.confirmButton.text}
                            borderRadius={"10px"}
                            background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
                            color={"#ffffff"}
                            onClick={handleDoneClick}
                            disabled={!isFormComplete}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WifiFrom;

