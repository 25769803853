
import React, { useEffect, useState } from "react";
import InfoBox from "../../components/InfoBox/infobox";
import RectangleCardHome from "../RectangleCard/RectangleCard";
import AddNewDevice from "../RectangleCard/AddNewDevice";
import { useNavigate } from "react-router-dom";
import AuthService from "../../Auth/auth";
import Loader from "../loader/loader"

const Home = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [deviceInfo, setDeviceInfo] = useState()

    const cardClick = (assetId, assetName) => {
        navigate("/PAMDashboard", {
            state: {
                assetId,
                assetName
            },
        })
    }

    const addNewDevice = () => {
        console.log("Add New Device")
        navigate("/Setting", {
            state: {
                setting: {
                    scanAll: true,
                }
            },
        })
    }

    useEffect(() => {

        async function fetchPAMDeviceInfo() {
            setLoading(true)
            let userInfo = await JSON.parse(localStorage.getItem("spaceio-userInfo"))
            let deviceInfo = await AuthService.getPAMDeviceInfo(userInfo.id);

            for (const obj of deviceInfo) {
                let pamData = await AuthService.getPAMData(obj.assetId);
                // console.log("pamData", pamData);

                if (pamData[0]) {
                    obj.deviceID = pamData[0].macId

                    const currentDateTime = new Date();
                    currentDateTime.setMinutes(currentDateTime.getMinutes() - 30);

                    const currentTimeInSeconds = Math.floor(currentDateTime.getTime() / 1000);

                    if (pamData[0].utcEpoch) {
                        obj.deviceStatus = (pamData[0].utcEpoch * 1000) < currentTimeInSeconds ? false : true
                    } else {
                        obj.deviceStatus = false;
                    }

                    // console.log(pamData[0].co2SCD40)
                    if (pamData[0].co2SCD40 < 1000) {
                        obj.roomHealthStatus = "Good";
                    }
                    else if (pamData[0].co2SCD40 > 1000 && pamData[0].co2SCD40 < 2000) {
                        obj.roomHealthStatus = "Moderate";
                    } else if (pamData[0].co2SCD40 > 2000) {
                        obj.roomHealthStatus = "Bad"
                    }
                }
            };
            // console.log("device info", deviceInfo);
            setDeviceInfo(deviceInfo);
            setLoading(false)
        }

        fetchPAMDeviceInfo();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className="row g-0 py-2 px-6 ">
                <div className=" pt-6">
                    <InfoBox text={"You can use following options to get started."} />
                    <div className="pt-2">
                        <AddNewDevice
                            text="+ Add New Device"
                            onClick={() => addNewDevice()}
                        />
                    </div>
                    {
                        // <RectangleCardHome
                        //     key={0}
                        //     spaceName={"High Table (Mumbai)"}
                        //     deviceStatus={true} // You can provide the actual device status here
                        //     deviceId={"2301-101-006"}
                        //     roomHealthStatus={"Moderate"} // You can provide the actual room health status here
                        //     onClick={() => cardClick("2966270c-7b43-475c-b9d4-dcb68109ca1c", "High Table (Mumbai)")}
                        // />


                        deviceInfo ? (
                            deviceInfo.map((device, index) => (
                                <RectangleCardHome
                                    key={index}
                                    spaceName={device.assetName}
                                    deviceStatus={device.deviceStatus} // You can provide the actual device online status here
                                    // deviceId={device.deviceID}
                                    roomHealthStatus={device.roomHealthStatus} // You can provide the actual room health status here
                                    onClick={() => cardClick(device.assetId, device.assetName)}
                                />
                            ))
                        ) : (
                            <></>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default Home;