import Axios from "axios";
import moment from 'moment';


const AuthService = {

    getAuthToken: async (authCode) => {
        try {
            const res = await Axios({
                headers: {
                    "x-auth-code": authCode,
                },
                method: 'post',
                url: 'https://accountws-shared-01.qa.afreespace.com/api/login/code'
            });
            return res;
        } catch (err) {
            console.error(err);
            throw (err.response.data.error)
        }
    },

    authenticationKey: function () {
        return localStorage.getItem("spaceio-key") ? localStorage.getItem("spaceio-key") : null
    },

    login: async (username, password) => {
        try {
            const response = await Axios.post("https://prodws.afreespace.com/api/login", {
                "username": username,
                "password": password
            })
            console.log(response);
            const key = (response.headers["x-auth-key"])
            window.localStorage.setItem("spaceio-key", (key))
            return true
        } catch (err) {
            throw (err.response.data.error)
        }
    },

    logout: () => {
        window.localStorage.removeItem("spaceio-key");
    },

    getPAMDeviceInfo: async (userId) => {

        // userId = 294621;
        let key = localStorage.getItem("spaceio-key")
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://accountws-shared-01.qa.afreespace.com/api/pam-user?id=${userId}`,
            headers: {
                'x-auth-key': key
            }
        };

        try {
            const result = await Axios.request(config);
            return result.data.pamUsersAssetData;
        } catch (err) {
            console.error(err);
            throw (err.response.data.error)
        }

        // let result = {
        //     "data": {
        //         "city": "Mumbai",
        //         "country": "India",
        //         "state": "Maharastra",
        //         "location": "Household",
        //         "pamUsersAssetData": [
        //             {
        //                 "assetId": "2966270c-7b43-475c-b9d4-dcb68109ca1c",
        //                 "assetName": "High Table (Mumbai)"
        //             },
        //             {
        //                 "assetId": "341b3bc6-a6ca-4804-aa54-1ec1e63e62ca",
        //                 "assetName": "Meeting Room (Mumbai)"
        //             },
        //             {
        //                 "assetId": "13b18e83-3403-4a94-89d4-fd6d29afdd86",
        //                 "assetName": "DEMO DEVICE 1"
        //             },
        //             {
        //                 "assetId": "023e186c-9733-4ca8-b2a9-26a21bf8914a",
        //                 "assetName": "DEMO DEVICE 2"
        //             }
        //         ]
        //     }
        // }

        // return result.data.pamUsersAssetData;

    },

    getPAMData: async (assetIdArr) => {

        let data = JSON.stringify([
            assetIdArr
        ]);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://spotws.afreespace.com/assets/data/12/current',
            headers: {
                'x-location-token': process.env.REACT_APP_X_LOCATION_TOKEN,
                'authorization': process.env.REACT_APP_AUTHORIZATION_KEY,
                'Content-Type': 'application/json'
            },
            data: data
        };
        try {
            const result = await Axios.request(config);
            return result.data;
        } catch (err) {
            console.error(err);
            throw (err.response.data.error)
        }
    },

    getAssetInfo: async (assetIdArr) => {
        const headers = {
            'x-location-token': process.env.REACT_APP_X_LOCATION_TOKEN,
            'authorization': process.env.REACT_APP_AUTHORIZATION_KEY,
            'Content-Type': 'application/json'
        };

        try {
            const response = await Axios.get(`https://spotws.afreespace.com/assets/${assetIdArr}`, { headers });
            // console.log(response.data);
            return response.data;
        } catch (error) {
            console.log('error', error);
        }
    },

    // x-auth-key is hardcode for getTrendsChart
    // Account Ui x-auth-key is need 
    getTrendsChart: async (assetIdArr) => {

        // let key = localStorage.getItem("spaceio-key")

        let data = JSON.stringify({
            "tzTime": moment().format("YYYYMMDD"),
            "slotData": true,
            "assetIds": [assetIdArr]
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://preprod-analytics.afreespace.com/apiv2/pamc/current-data',
            headers: {
                'Content-Type': 'application/json',
                'x-auth-key': 'eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMjExNzMiLCJhdWQiOiJodHRwczovL2FjY291bnQuYWZyZWVzcGFjZS5jb20iLCJleHAiOjE2ODc4OTk4OTUsImFpZCI6MTIsImlhdCI6MTY4NzI1MTg5NX0.M3_KyNN02eMuqV-N0VeZJ1WmNJjHpIROxuoKN7BzbhIgZlKFFo2bJ0IL15VXSLndvojxzDlvQEGv0Std6e1mDbmoA2tNhDeUtFNO_Ulyp4h7aXU1ZZZjHmwP43UzrM-J3h-5TCCZNvJT1dhLDz2RfzyWXxKlfU6D2qi8sAhJ13bPECsqr-bttYiKcPAFXiNRd6GUQ9tJDHvALGbFkXBpCWR-ahyqUz_juAGMidV8uIEopylNKkFfK_8TzwAAcu_Yz4PE7ddK4NXr_kAKFhdEzcF2WYXCqvZfHtNw4bLle9slVJ8tf0o2SeNWedXPoFcBJs6kUevF4I0SSU7jXtOpRA',
                // 'x-auth-key': key,
                'authorization': process.env.REACT_APP_AUTHORIZATION_KEY,
            },
            data: data
        };

        try {
            const result = await Axios.request(config);
            return result.data;
        } catch (err) {
            console.error('axios err', err);
            throw (err.response.data.error)
        }

    },

    getAdvertName: async (assetIdArr) => {
        let data = JSON.stringify([
            assetIdArr
        ]);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://spotws.afreespace.com/assets/data/105/current',
            headers: {
                'x-location-token': process.env.REACT_APP_X_LOCATION_TOKEN,
                'authorization': process.env.REACT_APP_AUTHORIZATION_KEY,
                'Content-Type': 'application/json'
            },
            data: data
        };

        try {
            const result = await Axios.request(config);
            return result.data[0];
        } catch (err) {
            console.error(err);
            throw (err.response.data.error)
        }
    },

    getUserInfo: async (userId) => {

        let key = localStorage.getItem("spaceio-key")
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://accountws-shared-01.qa.afreespace.com/api/pam-user?id=${userId}`,
            headers: {
                'x-auth-key': key
            }
        };

        try {
            const result = await Axios.request(config);
            return result.data;
        } catch (err) {
            console.error(err);
            throw (err.response.data.error)
        }
    },

    updatedUserDeviceInfo: async (userId, arrayOfDevice) => {

        console.log(userId, arrayOfDevice)

        let data = JSON.stringify({
            "pamUsersAssetData": arrayOfDevice
        });

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `https://accountws-shared-01.qa.afreespace.com/api/pam-user?pamUserId=${userId}`,
            headers: {
                'x-auth-key': 'eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMzEyMDAiLCJhdWQiOiJodHRzOi8vYWNjb3VudC5kZXYuYWZyZWVzcGFjZS5jb20iLCJleHAiOjE2ODUxNDM5NTUsImFpZCI6MTIsImlhdCI6MTY4NDQ5NTk1NX0.LS31RfI4Y5gAHaIGoDx7Nn-vmZIuNG50UBmPEHpAgJu_sZV9Ja6TIGaeh-JbBYz_sn-9nUKhVeePD97DhvRULfe9EnUX5kkk7MH--1tR8hgMFSuiTUYAHj-H0zR90w7E4LgiJjQ02bOdZCi89_umCUxkxyAo5VqQS_YdZvs5Dh-bb0zugtGSYYnLjhdQlq5mW3ly1mdFxp5D5jnEGElaYkeQYS69myijn-S7l5TspCjBFjJEuIQEiSjxC3Bkxm_DgOaMP2TOCrajMYnSf6HQ27iSNMBsfc4kNkM7RCQtsiiuet1QEU_XjWm4a0V37e1s5HvNPelV7jn2RxdN91UsRg',
                'Content-Type': 'application/json'
            },
            data: data
        };

        Axios.request(config)
            .then((response) => {
                // console.log(response.status)
                return response.status
            })
            .catch((error) => {
                console.log(error);
            });
    },

    updatedUserInfo: async (userId, userinfo) => {
        let data = JSON.stringify({
            "city": userinfo.city,
            "country": userinfo.country,
            "state": userinfo.state,
            "location": userinfo.locationType
        });

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `https://accountws-shared-01.qa.afreespace.com/api/pam-user?pamUserId=${userId}`,
            headers: {
                'x-auth-key': 'eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMzEyMDAiLCJhdWQiOiJodHRzOi8vYWNjb3VudC5kZXYuYWZyZWVzcGFjZS5jb20iLCJleHAiOjE2ODUxNDM5NTUsImFpZCI6MTIsImlhdCI6MTY4NDQ5NTk1NX0.LS31RfI4Y5gAHaIGoDx7Nn-vmZIuNG50UBmPEHpAgJu_sZV9Ja6TIGaeh-JbBYz_sn-9nUKhVeePD97DhvRULfe9EnUX5kkk7MH--1tR8hgMFSuiTUYAHj-H0zR90w7E4LgiJjQ02bOdZCi89_umCUxkxyAo5VqQS_YdZvs5Dh-bb0zugtGSYYnLjhdQlq5mW3ly1mdFxp5D5jnEGElaYkeQYS69myijn-S7l5TspCjBFjJEuIQEiSjxC3Bkxm_DgOaMP2TOCrajMYnSf6HQ27iSNMBsfc4kNkM7RCQtsiiuet1QEU_XjWm4a0V37e1s5HvNPelV7jn2RxdN91UsRg',
                'Content-Type': 'application/json'
            },
            data: data
        };

        await Axios.request(config)
            .then((response) => {
                return response.status
            })
            .catch((error) => {
                console.log(error);
            });

    },

    updateBleToSpot: async (assetId, advertName, ssid) => {
        let data = JSON.stringify([
            {
                "assetId": assetId,
                "data": {
                    "utcEpoch": new Date().getTime(),
                    "advertName": advertName,
                    "ssid": ssid
                }
            }
        ]);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://spotws.afreespace.com/ingest/105?asyncCall=true',
            headers: {
                'x-location-token': process.env.REACT_APP_X_LOCATION_TOKEN,
                'authorization': process.env.REACT_APP_AUTHORIZATION_KEY,
                'Content-Type': 'application/json'
            },
            data: data
        };

        try {
            const result = await Axios.request(config);
            console.log(JSON.stringify(result.data));
        } catch (err) {
            console.error(err);
            throw (err.response.data.error)
        }

    }





}

export default AuthService;