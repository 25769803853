import { useState } from "react";
import "./RectangleCard.css";
import goodIcon from "../../image/good.svg";
import moderateIcon from "../../image/moderate.svg";
import badIcon from "../../image/bad.svg";
import greyIcon from "../../image/grey.svg";


function RectangleCardHome({ spaceName, deviceStatus, deviceId, roomHealthStatus, onClick }) {

    // eslint-disable-next-line no-unused-vars
    const [buttonOnClick, setButtonOnClick] = useState(2);

    const deviceOnlineOffline = deviceStatus ? "Online" : "Offline"
    const textClassName = deviceStatus ? "green-text" : "red-text";
    let healthIcon;

    if (deviceStatus) {
        switch (roomHealthStatus) {
            case "Good":
                healthIcon = goodIcon;
                break;
            case "Moderate":
                healthIcon = moderateIcon;
                break;
            case "Bad":
                healthIcon = badIcon;
                break;
            default:
                healthIcon = null;
                break;
        }
    }
    else {
        healthIcon = greyIcon;
    }


    return (
        <div className="rectangle-card-timP-body p-3 my-3" onClick={buttonOnClick === 1 ? onClick : onClick} style={{ cursor: buttonOnClick === 1 ? "pointer" : "pointer" }}>
            <div className=" flex justify-between items-center">
                <div className=" flex flex-col ml-4">
                    <span className="rectangle-card-timP-body-top-content1">{spaceName}</span>
                    <div className="rectangle-card-timP-body-bottom">
                        <div className=" flex flex-row ">
                            <span className={textClassName}>{deviceOnlineOffline}</span>
                            <span className="ml-2">{deviceId ? deviceId : ""}</span>
                        </div>
                    </div>
                </div>
                <div className=" flex flex-col justify-center pl-2 pr-2">
                    {healthIcon &&
                        <img className="w-14 h-14 mx-auto" src={healthIcon} alt="Health Icon" />}
                </div>
            </div>
        </div>
    );
}

export default RectangleCardHome;