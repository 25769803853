/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import InfoBox from "../../components/InfoBox/infobox";
import RectangleCardSetting from "../RectangleCard/RectangleCardSetting";
import AuthService from "../../Auth/auth";

import StaticModal from "../mod1/StaticModal"
import infoIcon from "../../image/infoIcon.svg"
import { useNavigate } from "react-router-dom";

import WifiFrom from "../mod1/WiFiForm"
import Rename from "../mod1/Rename"
import Loader from "../loader/loader"


const Settings = () => {

    const location = useLocation();
    const locationState = location.state;

    const navigate = useNavigate();

    const scanAllFlage = locationState?.setting?.scanAll

    const [infoBoxText, setInfoBoxTetx] = useState({ text: "" })
    const [supportsBluetooth, setSupportsBluetooth] = useState(false);
    const [loading, setLoading] = useState(false)

    // eslint-disable-next-line no-unused-vars
    const [scanAllDevice, SetScanAll] = useState(scanAllFlage ? scanAllFlage : false);

    const [existiingDevice, setExistiingDevice] = useState([{}])

    const [deviceInfo, setDeviceInfo] = useState([{}])
    const [pairedDeviceInfo, SetPairedDeviceInfo] = useState([])

    const [saticModal, setSaticModal] = useState(false)

    const [powerCycleConfrimed, setPowerCycleConfrimed] = useState(scanAllFlage ? !scanAllFlage : true);
    const [powerCycleConfrimedForSelectedDevice, setPowerCycleConfrimedForSelectedDevice] = useState(false)
    const [showWifiFrom, setShowWifiFrom] = useState(false)
    const [showRename, setRename] = useState(false)
    const [showAddNewDevice, setShowAddNewDevice] = useState(false)

    const [slectedDeviceInfo, setSlectedDeviceInfo] = useState(null)

    const [BLEdevice, setBLEdevice] = useState(null);

    const [slectedDevicAssetId, setSlectedDeviceAssetId] = useState(null);

    const staticModalInfo = {
        primaryText: "Web Bluetooth API is not available for your browser!",
        secondaryText: "To Configure the Devices try to open the App in supported browser",
        icon: infoIcon,
        inputBox: false,
        confirmButtonText: "Home"
    }

    const staticModelPowerCycle = {
        primaryText: "Power Cycle the PAM devices ",
        secondaryText: "To power cycle the device you need use side button of the device ",
        icon: infoIcon,
        inputBox: false,
        confirmButtonText: "Power Cycle Confrimed "
    }

    const staticModalInfoForAddNewDevice = {
        primaryText: "You did not added any dvice in your account ",
        secondaryText: "To Configure new device to your account go to home page",
        icon: infoIcon,
        inputBox: false,
        confirmButtonText: "Home"
    }

    // useEffect(() => {
    //     console.log(existiingDevice);
    // }, [existiingDevice])

    useEffect(() => {
        async function fetchPAMDeviceInfo() {
            setLoading(true)
            let userInfo = await JSON.parse(localStorage.getItem("spaceio-userInfo"))
            let deviceInfo = await AuthService.getPAMDeviceInfo(userInfo.id);

            // setExistiingDevice(
            //     ...existiingDevice,
            //     {
            //         "assetId": deviceInfo.assetId,
            //         "assetName": deviceInfo.assetName
            //     }
            // )

            // console.log("deviceInfo", deviceInfo);
            if (deviceInfo.length === 0) {
                setShowAddNewDevice(true);
                setLoading(false)
                return;
            }
            for (const obj of deviceInfo) {
                let getAdvertName = await AuthService.getAdvertName(obj.assetId);
                // console.log("getAdvertName", getAdvertName);
                if (getAdvertName.advertName) {
                    obj.advertInfo = getAdvertName;
                    obj.paired = false;
                    obj.deviceBleStatus = false;
                }
            }

            setDeviceInfo(deviceInfo);
            setLoading(false)
        }

        if (!scanAllFlage) {
            fetchPAMDeviceInfo()
        }

    }, [])

    useEffect(() => {

        async function isWebBluetoothEnabled() {
            if (!navigator.bluetooth) {
                // Web Bluetooth API is not available in this browser!
                setInfoBoxTetx({ text: "Web Bluetooth API is not available in this browser!" });
                setSupportsBluetooth(false)
                setSaticModal(true)
            }
            else {
                // Web Bluetooth API is available in this browser!
                setInfoBoxTetx({ text: "You can configure below Devices" });
                setSupportsBluetooth(true)
                setSaticModal(false)
            }
        }
        isWebBluetoothEnabled();
    }, [])

    const onDisconnected = (event) => {
        // console.log(event);
        alert(`The device ${event.target.name} is disconnected`);

        setDeviceInfo(prevDeviceInfo => prevDeviceInfo.map(obj => {
            if (event.target.name === obj.advertInfo?.advertName) {
                return {
                    ...obj,
                    paired: false,
                    deviceBleStatus: false
                };
            }
            return obj;
        }))
        setShowWifiFrom(false)
        setSlectedDeviceAssetId(null)
        if (scanAllDevice) {
            navigate("/home")
        }
        // setIsDisconnected(true);
    }

    const connectToGattServer = async (device, ssid, psk) => {

        console.log(device, ssid, psk);
        try {
            const server = await device.gatt.connect();
            device.addEventListener("gattserverdisconnected", onDisconnected);

            const service = await server.getPrimaryService(
                "fada47be-c455-48c9-a5f2-af7cf368d719"
            );

            const characteristics = await service.getCharacteristics();
            console.log(characteristics);

            const ssidCharacteristic = characteristics.find(
                (c) => c.uuid === "5eba1956-32d3-47c6-81a6-a7e59f18dac0"
            );
            const pwdCharacteristic = characteristics.find(
                (c) => c.uuid === "ed694ab9-4756-4528-aa3a-799a4fd11117"
            );
            const updateCharacteristic = characteristics.find(
                (c) => c.uuid === "f299c342-8a8a-4544-ac42-08c841737b1b"
            );

            // Configure Wi-Fi settings by writing values to the characteristics

            const ssidValue = new TextEncoder().encode(ssid);
            const pwdValue = new TextEncoder().encode(psk);
            const triggerValue = new Uint8Array([1]);


            await ssidCharacteristic.writeValue(ssidValue);
            await pwdCharacteristic.writeValue(pwdValue);
            await updateCharacteristic.writeValue(triggerValue);

        } catch (error) {
            // console.error(error);
            if (error instanceof DOMException && error.code === DOMException.NOT_FOUND_ERR) {
                // User canceled the device selection
                console.log("Device selection canceled by the user.");
                navigate("/home")

            } else {
                // Other error occurred
                console.error("Error selecting Bluetooth device:", error);
            }
        }
        console.log("Wi-Fi settings configured successfully.");
    };

    const scanPAMOnBle = async (prefixName, specificName) => {

        console.log(prefixName, specificName);
        try {
            const filters = [];
            if (prefixName) {
                console.log("Scanning All");
                filters.push({ namePrefix: prefixName });
            }
            if (specificName) {
                console.log("Scanning specificName");
                filters.push({ name: specificName });
            }
            setPowerCycleConfrimed(true)
            setPowerCycleConfrimedForSelectedDevice(false)

            if (prefixName || specificName) {

                // Request access to the Bluetooth device
                const device = await navigator.bluetooth.requestDevice({
                    // filters: [
                    //     { services: ['fada47be-c455-48c9-a5f2-af7cf368d719'] }
                    // ],


                    filters: filters,
                    optionalServices: ['fada47be-c455-48c9-a5f2-af7cf368d719']
                });

                // console.log(device);
                setBLEdevice(device)

                // Connect to the GATT server
                const server = await device.gatt.connect();
                // console.log(server);




                device.addEventListener('gattserverdisconnected', onDisconnected);


                // Read Asset ID Logic Start 
                const service = await server.getPrimaryService(
                    "fada47be-c455-48c9-a5f2-af7cf368d719"
                );
                const characteristics = await service.getCharacteristics();
                const getAssetId = characteristics.find(
                    (c) => c.uuid === "01234567-89ab-cdef-0123-456789abcdef"
                );
                const assetIdValue = await getAssetId.readValue();
                const assetIdArray = new Uint8Array(assetIdValue.buffer);
                const assetId = await String.fromCharCode(...assetIdArray);
                console.log("AssetId : ", assetId);
                setSlectedDeviceAssetId(assetId);
                // Read Asset ID Logic End 


                console.log("Device selected:", device.name);
                if (prefixName) {
                    SetPairedDeviceInfo([{ name: device.name }]);
                } else if (specificName) {
                    setDeviceInfo(prevDeviceInfo => prevDeviceInfo.map(obj => {
                        if (specificName === obj.advertInfo?.advertName) {
                            return {
                                ...obj,
                                paired: true,
                                deviceBleStatus: true,
                                assetId: assetId

                            };
                        }
                        return obj;
                    }))
                }


            }

        } catch (error) {
            console.error(error);
            if (error instanceof DOMException && error.code === DOMException.NOT_FOUND_ERR) {
                // User canceled the device selection
                console.log("Device selection canceled by the user.");
            } else {
                // Other error occurred
                console.error("Error selecting Bluetooth device:", error);
            }
        }
    };

    const rename = async (deviceInfo, newName) => {
        console.log("rename")
        console.log(deviceInfo, newName);
        setRename(true);

        let userInfo = await JSON.parse(localStorage.getItem("spaceio-userInfo"))
        let userDevice = await AuthService.getPAMDeviceInfo(userInfo.id);
        console.log("Before Rename :", userDevice);

        // Find the object with matching assetId in myDevice array
        const matchedDevice = userDevice.find(device => device.assetId === deviceInfo.assetId);

        if (matchedDevice) {
            // Update the assetName with "Bed Room"
            matchedDevice.assetName = newName;
        }
        // console.log("after rename ", userDevice);

        let ExistingDevice = [];
        ExistingDevice.push(...userDevice.map(({ assetId, assetName }) => ({ assetId, assetName })));


        console.log(ExistingDevice);

        let renameDone = await AuthService.updatedUserDeviceInfo(userInfo.id, ExistingDevice)
        console.log(renameDone);
        if (renameDone === 200) {
            console.log("ok device Renamed")
            navigate("/home")
        }
        else {

        }
    }

    const configWiFi = async (belData, assetId, wifiData) => {
        // console.log("configWiFi : ", belData.name, assetId, wifiData)
        await AuthService.updateBleToSpot(assetId, belData.name, wifiData.ssid)
        let userInfo = await JSON.parse(localStorage.getItem("spaceio-userInfo"))
        let userDevice = await AuthService.getPAMDeviceInfo(userInfo.id);
        let ExistingDevice = [];
        console.log(userDevice);

        ExistingDevice.push(...userDevice.map(({ assetId, assetName }) => ({ assetId, assetName })));


        // ExistingDevice.push(
        //     {
        //         "assetId": userDevice[0].assetId,
        //         "assetName": userDevice[0].assetName
        //     }
        // )
        ExistingDevice.push(
            {
                "assetId": assetId,
                "assetName": wifiData.deviceName
            }
        )
        console.log(ExistingDevice)

        let newDeviceAdded = await AuthService.updatedUserDeviceInfo(userInfo.id, ExistingDevice)

        if (newDeviceAdded === 200) {
            console.log("ok device added ")
            navigate("/home")
        }
        else {

        }
    }

    const slectedDeviceForWiFiConfig = async (deviceInfo) => {
        console.log("...............................", deviceInfo)
        setSlectedDeviceInfo(deviceInfo);
        setPowerCycleConfrimedForSelectedDevice(true)
    }

    const slectedDeviceForRename = async (deviceInfo) => {
        console.log("...............................", deviceInfo)
        setSlectedDeviceInfo(deviceInfo);
        setRename(true)
    }

    return (
        <>
            {loading && <Loader />}
            <div className="row g-0 py-2 px-6">
                <div className="pt-6">
                    <InfoBox className="pb-8" text={infoBoxText.text} />
                    {supportsBluetooth && !saticModal ?
                        <>
                            {scanAllDevice ?
                                <>
                                    {pairedDeviceInfo ? pairedDeviceInfo.map((device, index) => (
                                        <RectangleCardSetting
                                            key={index}
                                            myDevice={false}
                                            isparied={true}
                                            spaceName={device.name}
                                            deviceBleStatus={true}
                                            onWiFiClick={() => setShowWifiFrom(true)}
                                        />))
                                        : <></>
                                    }
                                </>
                                :
                                <>
                                    {deviceInfo ?
                                        <>
                                            {deviceInfo.map((device, index) => (
                                                <RectangleCardSetting
                                                    key={index}
                                                    myDevice={true}
                                                    isparied={device.paired}
                                                    spaceName={device.assetName}
                                                    deviceId={device.advertInfo ? device.advertInfo.advertName : "No BLE name"}
                                                    deviceBleStatus={device.deviceBleStatus} // You can provide the actual device online status here
                                                    onBLEClick={() => slectedDeviceForWiFiConfig(device)} // scan for this device only 
                                                    onRename={() => slectedDeviceForRename(device)} // rename device 
                                                    onWiFiClick={() => setShowWifiFrom(true)}
                                                />))
                                            }
                                            {powerCycleConfrimedForSelectedDevice ?
                                                <StaticModal
                                                    text={{
                                                        primaryText: staticModelPowerCycle.primaryText,
                                                        secondaryText: staticModelPowerCycle.secondaryText,
                                                        icon: staticModelPowerCycle.icon,
                                                    }}
                                                    // closeModal: true
                                                    closeModal={() => {
                                                        setSlectedDeviceInfo(null);
                                                        setPowerCycleConfrimedForSelectedDevice(false)
                                                        // navigate("/home")
                                                    }}
                                                    confirmButton={{
                                                        text: staticModelPowerCycle.confirmButtonText,
                                                        onClick: () => {
                                                            scanPAMOnBle(null, slectedDeviceInfo.advertInfo?.advertName)
                                                        }
                                                    }}
                                                />
                                                :
                                                <></>
                                            }

                                        </>
                                        : <div>
                                            <h3>Plase add a device from home pg</h3>
                                        </div>
                                    }
                                </>
                            }
                        </>
                        : <>
                            {/* {we will so this StaticModal if User 
                                        device is not support the BLE   } */}
                            <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                                <StaticModal
                                    text={{
                                        primaryText: staticModalInfo.primaryText,
                                        secondaryText: staticModalInfo.secondaryText,
                                        icon: staticModalInfo.icon
                                    }}
                                    // closeModal={() => setShowModal(false)}
                                    confirmButton={{
                                        text: staticModalInfo.confirmButtonText,
                                        onClick: () => {
                                            setSaticModal(false)
                                            navigate("/home")
                                        }
                                    }}
                                />
                            </div>
                        </>
                    }
                    {!powerCycleConfrimed ?
                        <>
                            <StaticModal
                                text={{
                                    primaryText: staticModelPowerCycle.primaryText,
                                    secondaryText: staticModelPowerCycle.secondaryText,
                                    icon: staticModelPowerCycle.icon,
                                }}
                                // closeModal: true
                                closeModal={() => {
                                    setPowerCycleConfrimed(true)
                                    navigate("/home")
                                }}
                                confirmButton={{
                                    text: staticModelPowerCycle.confirmButtonText,
                                    onClick: () => {
                                        scanPAMOnBle("FS_PAM", null)
                                    }
                                }}
                            />
                        </>
                        :
                        <></>
                    }
                    {showWifiFrom ?
                        <>
                            <WifiFrom

                                nameofDevice={slectedDeviceInfo?.assetName}
                                closeModal={() => {
                                    setShowWifiFrom(false)
                                }}

                                confirmButton={{
                                    text: "Configure",
                                    onClick: async (wifiAndName) => {
                                        console.log("wifi is set : ", wifiAndName)
                                        setShowWifiFrom(false)
                                        await connectToGattServer(BLEdevice, wifiAndName.ssid, wifiAndName.psk);
                                        await configWiFi(BLEdevice, slectedDevicAssetId, wifiAndName) // as we haved a pired device we can updated wifi 
                                        navigate("/home")
                                    }
                                }}
                            />
                        </>
                        :
                        <></>
                    }
                    {showRename ?
                        <>
                            <Rename
                                nameofDevice={slectedDeviceInfo?.assetName}
                                closeModal={() => {
                                    setSlectedDeviceInfo(null);
                                    setRename(false)
                                }}
                                confirmButton={{
                                    text: "Customize the name",
                                    onClick: (newName) => {
                                        console.log("New Name : ", newName)
                                        rename(slectedDeviceInfo, newName);
                                        setRename(false)
                                        navigate("/home")
                                    }
                                }}

                            />
                        </>
                        :
                        <></>
                    }
                    {showAddNewDevice ?
                        <>
                            <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                                <StaticModal
                                    text={{
                                        primaryText: staticModalInfoForAddNewDevice.primaryText,
                                        secondaryText: staticModalInfoForAddNewDevice.secondaryText,
                                        icon: staticModalInfoForAddNewDevice.icon
                                    }}
                                    // closeModal={() => setShowModal(false)}
                                    confirmButton={{
                                        text: staticModalInfoForAddNewDevice.confirmButtonText,
                                        onClick: () => {
                                            setShowAddNewDevice(false)
                                            navigate("/home")
                                        }
                                    }}
                                />
                            </div>
                        </>
                        :
                        <></>
                    }
                </div>
            </div>
        </>
    )
}
export default Settings;