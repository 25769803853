import React from 'react'
import PAMLogo from "../image/PAMCloudLogo.svg";
import Logout from "../image/logoutIcon.svg"
// import User from "../image/user.svg"
import AuthService from "../Auth/auth";
import { useNavigate } from "react-router-dom";

import "./../components/PAMDashboard/PAMDashboard.css";

function Header() {

    const navigate = useNavigate();

    const goBack = () => {
        AuthService.logout()
        navigate("/")
    }

    return (
        <>
            <div className="Header bg-white  ">
                <div className="container-fluid g-0">
                    <div className="row justify-content-center g-0 mainDiv">
                        <div className="col-lg-8 max-h-full" style={{ background: "#2e375b", display: "flex", flexDirection: "column" }}>
                            <div className="flex flex-row justify-between items-center py-3  px-6">
                                <div className="flex flex-row justify-center items-center">
                                    <img className=" w-12 h-12" src={PAMLogo} alt={"Logo"} />
                                    <div className='flex flex-col justify-start pl-3 text-[#38D68B] font-nunito'>
                                        <h3 className='text-[24px] sm:text-[12px] md:text-[24px] font-[700] m-0'>Pure Air Module</h3>
                                        <p className='text-[14px] sm:text-[7px] md:text-[14px] font-[500] text-opacity-5 m-0' style={{ opacity: "0.8" }}>
                                            Your air quality monitoring platform.
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <img
                                        className='h-8 w-8 text-white cursor-pointer'
                                        src={Logout}
                                        alt="logOut"
                                        onClick={goBack}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Header