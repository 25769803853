import React, { useEffect, useState } from "react";

import { Country, State, City } from 'country-state-city';
import AuthService from "../../Auth/auth";

import stateLogo from "../../image/countryIcon.svg";
import cityLogo from "../../image/city.svg";
import locationTypeLogo from "../../image/location.svg";

import Button1 from "../Button1";
import Loader from "../loader/loader"
import TailWindDropDown from "../DropDown/TailWindDropDown";


const Profile = () => {

    const [loading, setLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false);

    const [userProfileInfo, setUserProfileInfo] = useState({
        userImage: "",
        userName: "",
        userEmail: "",
        country: "",
        state: "",
        city: "",
        locationType: ""
    });

    const [cancel, setCancel] = useState(false);

    const options = [
        { value: 'HouseHold', label: 'HouseHold' },
        { Value: 'Workplace', label: 'Workplace' },
        { value: 'Warehouse/Factory', label: 'Warehouse/Factory' },
        { value: 'Hospital', label: 'Hospital' },
        { value: 'Public Place', label: 'Public Place' },
        { value: 'Transport/Vehicle', label: 'Transport/Vehicle' },
        { value: 'Classroom', label: 'Classroom' },
        { value: 'Laboratory', label: 'Laboratory' },
        { value: 'Others', label: 'Others' }
    ]

    const countries = Country.getAllCountries();
    const countryOptions = countries.map(country => ({
        value: country.isoCode,
        label: country.name
    }))

    const [stateOptions, setStateOptions] = useState(
        State.getStatesOfCountry().map(state => ({
            value: state.name,
            label: state.name
        }))
    )

    const [cityOptions, setCityOptions] = useState(
        City.getCitiesOfCountry().map(city => ({
            value: city.name,
            label: city.name
        }))
    )

    const [isOpen, setIsOpen] = useState({
        isOpenLocationType: false,
        iscountry: false,
        isState: false,
        isCity: false
    });

    const handleOnEditButton = (key) => {
        const updatedOpenState = Object.keys(isOpen).reduce((acc, currKey) => {
            if (currKey === key) {
                return {
                    ...acc,
                    [currKey]: !isOpen[currKey],
                };
            } else {
                return {
                    ...acc,
                    [currKey]: false,
                };
            }
        }, {});
        setIsOpen(updatedOpenState);
    }

    const handleSelectOptions = (key, optionData) => {
        if (key === "isOpenLocationType") {
            setUserProfileInfo({
                ...userProfileInfo,
                locationType: optionData.label
            })
        }
        else if (key === "iscountry") {
            setStateOptions(
                State.getStatesOfCountry(optionData.value).map(state => ({
                    value: state.name,
                    label: state.name
                }))
            )
            setUserProfileInfo({
                ...userProfileInfo,
                country: optionData.label,
                countryCode: optionData.value
            })
        }
        else if (key === "isState") {
            setCityOptions(
                City.getCitiesOfCountry(userProfileInfo.countryCode).map(city => ({
                    value: city.name,
                    label: city.name
                }))
            )
            setUserProfileInfo({
                ...userProfileInfo,
                state: optionData.label
            })
        }
        else if (key === "isCity") {
            setUserProfileInfo({
                ...userProfileInfo,
                city: optionData.label
            })
        }
        handleOnEditButton(key);
    }

    const updatedProfileInfo = async () => {
        let userInfo = await JSON.parse(localStorage.getItem("spaceio-userInfo"))
        await AuthService.updatedUserInfo(userInfo.id, userProfileInfo)
    }

    useEffect(() => {
        async function userInfo() {
            setLoading(true)
            let userId = await JSON.parse(localStorage.getItem("spaceio-userInfo"))
            let userInfo = await AuthService.getUserInfo(userId.id);
            // console.log(userInfo.user?.name);

            setUserProfileInfo({
                ...userProfileInfo,
                userName: userInfo.user?.name,
                userImage: userId.profileImage,
                userEmail: userId.email,
                country: userInfo.country,
                state: userInfo.state,
                city: userInfo.city,
                locationType: userInfo.location
            })
            setLoading(false)
            setCancel(false)
            // console.log(userInfo);
        }
        userInfo()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancel])

    return (
        <>
            {loading && <Loader />}
            <div style={{ height: '80vh' }} className="flex justify-center items-center  ">
                <div className="row g-0 py-2 px-6">
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex items-center space-x-4">
                            <div className="relative">
                                <img className="w-10 h-10 rounded-full" src={userProfileInfo.userImage} alt=""></img>
                                <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                            </div>
                            <div className="font-semibold text-black font-nunito ">
                                <div>{userProfileInfo.userName}</div>
                                <div className="text-sm text-gray-500 dark:text-gray-400">{userProfileInfo.userEmail}</div>
                            </div>
                        </div>

                        <div className="w-64 flex felx-row gap-2">
                            {isEditing ?
                                <>
                                    <Button1
                                        text={"Cancel"}
                                        borderRadius="6px"
                                        style={{ border: "1px solid #FF9777" }}
                                        background="transparent"
                                        color="#FF9777"
                                        onClick={() => {
                                            setIsEditing(!isEditing)
                                            setCancel(true)
                                            setIsOpen({
                                                ...isOpen,
                                                isOpenLocationType: false,
                                                iscountry: false,
                                                isState: false,
                                                isCity: false
                                            })
                                        }}
                                    />
                                    <Button1
                                        text={"Update Profile"}
                                        borderRadius="6px"
                                        background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)"
                                        color="#ffffff"
                                        onClick={() => {
                                            updatedProfileInfo()
                                            setIsEditing(!isEditing)
                                        }}
                                    />
                                </>
                                :
                                <>
                                    <Button1
                                        text={"Edit Profile"}
                                        borderRadius="6px"
                                        background="linear-gradient(90deg, #F7707D 0%, #F29173 100%)"
                                        color="#ffffff"
                                        onClick={() => {
                                            setIsEditing(!isEditing)
                                            // console.log("Save changed")
                                        }}
                                    />
                                </>
                            }
                        </div>

                        <div className="col-xl-4 col-lg-5 col-sm-6 col-10 w-60 font-nunito " style={{ flexGrow: "1", border: "1px " }}>
                            <div className='space-y-2 mt-2 flex flex-col'>
                                <label className=" mt-1 text-sm font-nunito font-medium" >Location Type</label>
                                <div className=" flex felx-row justify-between items-center">
                                    <div className=" flex flex-row items-center">
                                        <img src={locationTypeLogo} alt="area" className='h-5 w-5' />
                                        <label className="text-sm font-nunito font-semibold ml-2" >{userProfileInfo.locationType}</label>
                                    </div>
                                    <TailWindDropDown
                                        listOptions={options}
                                        ShowEditIcon={isEditing}
                                        onEditIcon={() => handleOnEditButton("isOpenLocationType")}
                                        isOpen={isOpen.isOpenLocationType}
                                        handleSelectOptions={(optionData) => handleSelectOptions("isOpenLocationType", optionData)}
                                    />
                                </div>
                            </div>
                            <div className='space-y-2 mt-2 flex flex-col'>
                                <label className=" mt-1 text-sm font-nunito font-medium" >Country</label>
                                <div className=" flex felx-row justify-between items-center">
                                    <div className=" flex flex-row items-center">
                                        <img src={stateLogo} alt="area" className='h-5 w-5' />
                                        <label className="text-sm font-nunito font-semibold ml-2" >{userProfileInfo.country}</label>
                                    </div>
                                    <TailWindDropDown
                                        listOptions={countryOptions}
                                        ShowEditIcon={isEditing}
                                        onEditIcon={() => handleOnEditButton("iscountry")}
                                        isOpen={isOpen.iscountry}
                                        handleSelectOptions={(optionData) => handleSelectOptions("iscountry", optionData)}
                                    />
                                </div>
                            </div>
                            <div className='space-y-2 mt-2 flex flex-col'>
                                <label className=" mt-1 text-sm font-nunito font-medium" >State</label>
                                <div className=" flex felx-row justify-between items-center">
                                    <div className=" flex flex-row items-center">
                                        <img src={stateLogo} alt="area" className='h-5 w-5' />
                                        <label className="text-sm font-nunito font-semibold ml-2" >{userProfileInfo.state}</label>
                                    </div>
                                    <TailWindDropDown
                                        listOptions={stateOptions}
                                        ShowEditIcon={isEditing}
                                        onEditIcon={() => handleOnEditButton("isState")}
                                        isOpen={isOpen.isState}
                                        handleSelectOptions={(optionData) => handleSelectOptions("isState", optionData)}
                                    />
                                </div>
                            </div>
                            <div className='space-y-2 mt-2 flex flex-col'>
                                <label className=" mt-1 text-sm font-nunito font-medium" >City</label>
                                <div className=" flex felx-row justify-between items-center">
                                    <div className=" flex flex-row items-center">
                                        <img src={cityLogo} alt="area" className='h-5 w-5' />
                                        <label className="text-sm font-nunito font-semibold ml-2" >{userProfileInfo.city}</label>
                                    </div>
                                    <TailWindDropDown
                                        listOptions={cityOptions}
                                        ShowEditIcon={isEditing}
                                        onEditIcon={() => handleOnEditButton("isCity")}
                                        isOpen={isOpen.isCity}
                                        handleSelectOptions={(optionData) => handleSelectOptions("isCity", optionData)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </>
    )
}
export default Profile;