import { useState } from "react";
import "./AddNewDevice.css";
const AddNewDevice = ({ text, onClick }) => {


    // eslint-disable-next-line no-unused-vars
    const [buttonOnClick, setButtonOnClick] = useState(2);

    return (
        <div className="add-new-device-body p-3 my-3" onClick={buttonOnClick === 1 ? onClick : onClick} style={{ cursor: buttonOnClick === 1 ? "pointer" : "pointer" }}>
            <div className=" flex justify-between">
                <div className=" flex flex-col ml-4">
                    <span className="add-new-device-text text-center">{text}</span>
                </div>
            </div>
        </div>
    )
}

export default AddNewDevice