import { React, useEffect, useState, } from 'react'
import { useLocation } from 'react-router-dom'
import "./PAMDashboard.css";
// import carbonCloud from "../../image/carbon_cloud-app.svg";
import carbonIcon from "../../image/carbon.svg";
import vocIcon from "../../image/voc.svg";
import tempIcon from "../../image/temp.svg";
import humidityIcon from "../../image/humidity.svg";

import 'bootstrap/dist/css/bootstrap.css'
import { Tabs, Tab } from 'react-bootstrap';
import NowCards from './NowCards';
import TrendsPage from './TrendsPage';
import InfoPage from './InfoPage';
import AuthService from "../../Auth/auth";
import Loader from "../loader/loader"

import moment from 'moment'
import 'moment-timezone';
// import tz from 'moment-timezone'


function PAMDashboard() {

    const location = useLocation();
    const locationState = location.state;
    const [activeTab, setActiveTab] = useState(1);

    // const [loading, setLoading] = useState(false)

    const [liveState, setLiveState] = useState({
        temp: null,
        co2: null,
        voc: null,
        humid: null,
        lastUpdateForHeaderCard: null
    })

    const [state, setState] = useState({

        tempUnit: false,       //false == celsius AND true == fahrenheit
        loadingGraph: true,
        loading: false,
        cDataArray: [],
        tDataArray: [],
        hDataArray: [],
        vDataArray: []

    })

    async function fetchPAMDataInfo() {

        // setLoading(true)

        let resForInfo = await AuthService.getAssetInfo(locationState.assetId);
        // console.log(resForInfo.timeZone);


        let pamData = await AuthService.getPAMData(locationState.assetId);
        const convertedTime = moment.unix(pamData[0].utcEpoch).tz(resForInfo.timeZone);
        setLiveState({
            ...liveState,
            temp: Number(pamData[0].temperatureSCD40).toFixed(2),
            voc: Number(pamData[0].humiditySCD40).toFixed(2),
            co2: Number(pamData[0].co2SCD40),
            humid: Number(pamData[0].humidityBME688).toFixed(2),
            lastUpdateForHeaderCard: convertedTime.format('DD MMM HH:mm'),
        })
        // console.log("pamData", pamData);



        setState({
            ...state,
            lastUpdate: moment.unix(pamData[0].utcEpoch).tz(resForInfo.timeZone),
        });

        let pamTrendChart = await AuthService.getTrendsChart(locationState.assetId);
        // console.log(pamTrendChart);



        if (Object.keys(pamTrendChart).length !== 0) {
            // processData(pamTrendChart, resForInfo.timeZone)

            if (state.tempUnit) {
                processData(pamTrendChart, resForInfo.timeZone)
                updateTempChart(!state.tempUnit)
            }
            else { processData(pamTrendChart, resForInfo.timeZone) }

        } else {
            setState({
                ...state,
                // loadingGraph: false
            })
        }
        // setLoading(false)
    }


    // useEffect(() => {
    //     console.log(state)
    // }, [state])

    useEffect(() => {
        // Function to be called initially
        fetchPAMDataInfo();

        // Function to be called every 1 minute
        const intervalId = setInterval(fetchPAMDataInfo, 60000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const updateTempUnit = (e) => {
        // console.log(state.tempUnit)
        if (state.tempUnit === false) {
            //false means celsius hence converting to fahreheit
            setLiveState({
                ...liveState,
                temp: liveState.temp && parseFloat((liveState.temp * 9 / 5 + 32).toFixed(1))
            })
            updateTempChart(state.tempUnit)
        }
        else {
            //converting fahrenheit to celsius
            setLiveState({
                ...liveState,
                temp: liveState.temp && parseFloat((liveState.temp - 32) * 5 / 9).toFixed(1)
            })
            updateTempChart(state.tempUnit)
        }
        setState({
            ...state,
            tempUnit: !state.tempUnit
        })
    }

    // eslint-disable-next-line no-unused-vars
    const updateTempChart = (tempUnit) => {
        if (state.tDataArray) {
            if (tempUnit) {
                //true means fahrenheit hence converting values to celsius
                let tDataArray = [...state.tDataArray]
                tDataArray.forEach((obj => obj.value = obj.value && parseFloat(((obj.value - 32) * 5 / 9).toFixed(1)))
                )
                setState({ tDataArray: tDataArray })
            }
            else {
                let tDataArray = [...state.tDataArray]
                tDataArray.forEach((obj => obj.value = obj.value && parseFloat((obj.value * 9 / 5 + 32).toFixed(1)))
                )
                setState({ tDataArray: tDataArray })
            }
        }
    }

    const handleTabSelect = (tabKey) => {
        setActiveTab(tabKey);
    };

    const processData = (data, timeZone) => {

        // console.log('------------------------', state)
        let cDataArray = [];
        let hDataArray = [];
        let tDataArray = [];
        let vDataArray = [];

        let cDataObj = {};
        let hDataObj = {};
        let tDataObj = {};
        let vDataObj = {};

        let timeObj = {};
        let timeBeforeOneHour = moment().tz(timeZone).subtract((60), 'minutes')
        const currentTime = moment().tz(timeZone);   //time sent by device and converted using timezone

        for (let addMinute = 0; timeBeforeOneHour <= currentTime; addMinute++) {
            let key = timeBeforeOneHour.format("HH:mm");
            timeObj[key] = null;
            timeBeforeOneHour.add(1, 'minutes');
        }

        for (const spaceValue of Object.values(data)) {
            for (const [timeKey, pamcData] of Object.entries(spaceValue)) {

                let timeKeyinFormat = moment.unix(timeKey).tz(timeZone).format('HH:mm');

                cDataObj[timeKeyinFormat] = Math.round(pamcData.co2SCD40) || null

                hDataObj[timeKeyinFormat] = Math.round(pamcData.humidityBME688) || null

                tDataObj[timeKeyinFormat] = (pamcData.temperatureBME688).toFixed(1) || null

                vDataObj[timeKeyinFormat] = Math.round(pamcData.vocIndexSGP40) || null
            }

            for (const key of Object.keys(timeObj)) {
                cDataArray.push({
                    year: key,
                    value: Math.round(cDataObj[key]) || null,
                });
                hDataArray.push({
                    year: key,
                    value: Math.round(hDataObj[key]) || null,
                });
                tDataArray.push({
                    year: key,
                    value: parseFloat(tDataObj[key]) || null,
                });
                vDataArray.push({
                    year: key,
                    value: parseFloat(vDataObj[key]) || null,
                });
                if (state.lastUpdate) {
                    if (key === state.lastUpdate.format("HH:mm")) {
                        break;
                    }
                }
            }
            // console.log('------------------------', state)


            setState({
                ...state,
                loadingGraph: false,
                cDataArray: cDataArray,
                tDataArray: tDataArray,
                hDataArray: hDataArray,
                vDataArray: vDataArray
            })
        }
    }

    return (
        <>
            {/* <div className="container-fluid g-0 ">
                <div className="row justify-content-center g-0 mainDiv"> */}
            {/* <div className="col-lg-8 max-h-full align-middle" style={{ background: "#2e375b", display: "flex", flexDirection: "column", justifyContent: "center" }}> */}
            {state.loading && <Loader />}
            <div className="row g-0 py-2 px-6 bg-[#2e375b]">
                <div className='device-time p-10'>
                    <div style={{ color: "white" }}>
                        <span className="device-time1">PAM NAME</span><br />
                        <span className="device-time2">{locationState.assetName}</span>
                    </div>
                    <div style={{ color: "white" }}>
                        <span className="device-time1">TIME OF MEASUREMENT</span><br />
                        <span className="device-time2">{liveState.lastUpdateForHeaderCard ? liveState.lastUpdateForHeaderCard : ""}</span>
                    </div>
                </div>
            </div>
            {/* </div> */}
            <div className="flex flex-col">
                <Tabs id="uncontrolled-tab-example" activeKey={activeTab} onSelect={handleTabSelect}>
                    <Tab eventKey={1} title="Now">
                        <NowCards
                            temp={liveState.temp}
                            co2={liveState.co2}
                            voc={liveState.voc}
                            humid={liveState.humid}

                            time={state.time}
                            selected={state.tempUnit}
                            toggleSelected={updateTempUnit}
                            loadingGraph={state.loadingGraph}
                        // reload={_reloadfunction()}
                        />
                    </Tab>
                    <Tab eventKey={2} title="Trends">
                        <div className='row no-gutters'>
                            <div className='col-lg-8 col-lg-12 linecharts'>
                                <div className='label-above-chart'>
                                    <span className='span-under-icon'><img src={carbonIcon} alt="carbonIcon" height="32px" width="32px"></img></span>
                                    <span style={{ color: "#2E375B", fontWeight: "700" }}>CO<sub>2</sub></span> &nbsp; <span style={{ opacity: "0.6" }}>{" (ppm)"}</span>
                                </div>

                                <TrendsPage
                                    dataForChart={state.cDataArray}
                                    outsideStrokeColor={"#F65162"}
                                    insideStrokeColor={"#4ECB71"}
                                    lowerBound={0}
                                    upperBound={1000}
                                />
                                <div className='label-above-chart'>
                                    <span className='span-under-icon'><img src={vocIcon} alt="vocIcon" height="32px" width="32px"></img></span>
                                    <span style={{ color: "#2E375B", fontWeight: "700" }}>VOC</span> &nbsp; <span style={{ opacity: "0.6" }}>{" (index)"}</span>
                                </div>
                                <TrendsPage
                                    dataForChart={state.vDataArray}
                                    outsideStrokeColor={"#F65162"}
                                    insideStrokeColor={"#4ECB71"}
                                    lowerBound={40}
                                    upperBound={50}
                                />
                                <div className='label-above-chart'>
                                    <span className='span-under-icon'><img src={tempIcon} alt="tempIcon" height="32px" width="32px"></img></span>
                                    <span style={{ color: "#2E375B", fontWeight: "700" }}>Temperature</span>&nbsp; <span style={{ opacity: "0.6" }}></span>
                                </div>
                                <TrendsPage
                                    dataForChart={state.tDataArray}
                                    outsideStrokeColor={"#F65162"}
                                    insideStrokeColor={"#4ECB71"}
                                    lowerBound={18}
                                    upperBound={25}
                                    temperature={true}
                                />
                                <div className='label-above-chart'>
                                    <span className='span-under-icon'><img src={humidityIcon} alt="humidityIcon" height="32px" width="32px"></img></span>
                                    <span style={{ color: "#2E375B", fontWeight: "700" }}>Humidity</span> &nbsp; <span style={{ opacity: "0.6" }}>{"(RH%)"} </span>
                                </div>
                                <TrendsPage
                                    dataForChart={state.hDataArray}
                                    outsideStrokeColor={"#F65162"}
                                    insideStrokeColor={"#4ECB71"}
                                    lowerBound={40}
                                    upperBound={50}
                                />
                            </div>
                        </div>
                    </Tab>
                    {/* <Tab eventKey={3} title="Info" >
                        <div className="heading p-4" >
                            <InfoPage />
                        </div>
                    </Tab> */}
                </Tabs>
            </div>
            {/* </div>
            </div> */}
        </>
    )
}

export default PAMDashboard


