import "./mod1.css"
import Button1 from "../Button1";
import closeIcon from "../../image/closeIcon.svg"

function StaticModal(props) {
    return (
        <div style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 1 }}>
            <div className="Mod1-parent-container">
                {props.closeModal ?
                    <button className="Mod1-parent-container-content-close-icon" aria-label="close-icon" onClick={props.closeModal}>
                        <img alt="closeIcon" src={closeIcon} ></img>
                    </button> : null
                }
                <img src={props.text.icon} alt="icon" className=" Mod1-parent-container-content1"></img>
                <span className="Mod1-parent-container-content2 my-3">{props.text['primaryText']}</span>
                <span className="Mod1-parent-container-content3 my-2">{props.text['secondaryText']}</span>


                <div className="Mod1-parent-container-content5">
                    <Button1
                        text={props.confirmButton.text}
                        borderRadius={"10px"}
                        background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
                        color={"#ffffff"}
                        onClick={props.confirmButton.onClick}
                        style={{ marginBottom: "0px" }}
                    />
                </div>
            </div>
        </div >);
}

export default StaticModal;